import { LocationQuery } from 'vue-router';
const persistedQueryParams = ['platform', 'merchantid', 'sourceid', 'gad_source', 'gclid'];
const utm = 'utm_';

// return true if the query params are the same
const compareFromAndToQueryKeys = (fromQuery: LocationQuery, toQuery: LocationQuery): boolean => {
  const fromKeys = Object.keys(fromQuery);
  const toKeys = Object.keys(toQuery);

  const areKeysEqual = fromKeys.length === toKeys.length && fromKeys.every((key) => toQuery[key] === fromQuery[key]);
  const fromQueryKeysContainsPersistedParams = fromKeys.some((key) => persistedQueryParams.includes(key) || key.startsWith(utm));
  const toQueryKeysContainsPersistedParams = toKeys.some((key) => persistedQueryParams.includes(key) || key.startsWith(utm));

  return !areKeysEqual && fromQueryKeysContainsPersistedParams && !toQueryKeysContainsPersistedParams;
};

export default defineNuxtRouteMiddleware((to, from) => {
  if (compareFromAndToQueryKeys(from.query, to.query)) {
    const query = {
      ...to.query
    };

    Object.keys(from.query).forEach((key) => {
      if (persistedQueryParams.includes(key) || key.startsWith(utm)) {
        query[key] = from.query[key];
      }
    });

    return navigateTo({
      ...to,
      query
    });
  }
});
